
import {defineComponent} from "vue";
import axios from "axios";
import * as requestActions from "../store/requestActions";

export default defineComponent({
  name: "Signpage",
  props: ["roomId", "isAdmin"],
  data() {
    return {
      phoneNumber: '',
      loaded: false,
      notFoundFilesToSign: false,
      alreadyLoaded: 0,
      selectedCA: null,
      password: '',
      fileListModalVisible: false,
      files: [], // Array to store the list of files from the API
      signingInProgress: false, // Flag to track if signing is in progress
      allFileSigned: false,
      error: false,
      allFilesCount: 0,
      signedAlreadyCounter: 0,
      allSignedCounter: 0,
      nonSignedFilesCount: 0,

      reloginCounter: 0,

      scriptUrls: [
        '/worker.js',
        '/euscpt.js',
        '/euscpm.js',
        '/euscp.js',
      ],
      currentIndex: 0,

    };
  },
  watch: {
  },
  computed: {
    photo: function () {
      return this.files?.filter(i => i.type === 'photo')
    },
    video: function () {
      return this.files?.filter(i => i.type === 'video')
    },
  },
  methods: {
    playVideo(index) {
      const video = this.$refs[`videoRef-${index}`];
      video.play();
    },

    errorNotificationFunc(text) {
      this.$emit('hideSign');
      requestActions.notify({
        type: "danger",
        text: text,
      });
    },

    async arrayBuffToImg(type, url) {

        try {
          const inputLink = this.normalizeUrl(url);

          let response = await axios.get(process.env.VUE_APP_SERVER_LINK + inputLink, {
            responseType: 'arraybuffer',
            headers: !this.isAdmin ? {} : {
              "x-access-token": this.getToken()
            },
          });

          let buffer = response.data;

          response = null;

          const blob = new Blob([buffer], { type: type === 'photo' ? 'image/png' : 'video/webm' });

          buffer = null;

          return URL.createObjectURL(blob);
        } catch (error) {
          console.error('Error converting arraybuffer to base64:', error);
          return null;
        }
    },
    onSignKeySubmit() {
      // Handle form submit if needed
    },

    getFileUrl() {
      if(this.isAdmin) {
        return process.env.VUE_APP_SERVER_LINK + `/room-file-signed/${this.roomId}`;
      } else {
        return process.env.VUE_APP_SERVER_LINK + `/room-file/${this.roomId}`;
      }
    },

    getToken() {
      return localStorage.getItem('token');
    },

    getHeaders() {
      if(this.isAdmin) {
        return {
          headers: {
            "x-access-token": this.getToken()
          }
        };
      } else {
        return { credentials: 'include', };
      }
    },

    async fetchFiles() {
      try {
        const response = await fetch(this.getFileUrl(), this.getHeaders());
        const data = await response.json();

        if(data?.files.length <= 0 ) {
          if(data?.returnToSign) {
            if(!this.isAdmin) {
              this.$emit('already-sign');
            }
          }
          this.$emit('hideSign');
          this.notFoundFilesToSign = true;
        }

        this.files = await Promise.all(data?.files?.map(async (file) => {
          return {
            name: file.url.split('/').pop(),
            url: this.isAdmin ? file.url : file.url.replace('/file', '/file-client'),
            type: file.type,
            extension: file.extension,
            signed: file.signed,
            inProgress: false,
            original: file.originalUrl ?? null,
            base64: !this.isAdmin ? await this.arrayBuffToImg(file.type, this.isAdmin ? file.url : file.url.replace('/file', '/file-client')) : null,
          };
        }));
        this.nonSignedFilesCount =  this.isAdmin ? this.files.length : this.files.filter((file) => !file.signed).length;
        if(data.files === undefined) {
          this.files = []
          return;
        }

        const allFilesCount = this.files.length;
        let signedAlreadyCounter = 0;
        for (const file of this.files) {
          if (file.signed && !this.isAdmin) {
            signedAlreadyCounter++;
          }
          if (signedAlreadyCounter === allFilesCount) {
            this.allFileSigned = true;
            if(!this.isAdmin) {
              this.$emit('already-sign');
            }
            this.$emit('hideSign');
            break;
          }
        }

        this.fileListModalVisible = true;
      } catch (error) {
        this.reloginCounter++;
        this.$emit('client-login', () => {
          if(this.reloginCounter <= 1) {
            this.fetchFiles();
          }
        })
        if(!this.isAdmin) {
          this.$emit('already-sign');
        }
        this.$emit('hideSign');
        this.notFoundFilesToSign = true;
        console.error('Error fetching files:', error);
      }
    },
    onSignReadClick() {
      //@ts-ignore
      __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.readKey"), '255,255,255,1', '0,0,0,1');
      const password = this.$refs.password;
      //@ts-ignore
      const key = document.querySelector('#sign-file').files;
      //@ts-ignore
      __PRVT_KEY__.readKey(key, password);
    },

    normalizeUrl(url) {
      const domainIndex = url.indexOf("://");
      const pathStart = domainIndex !== -1 ? url.indexOf("/", domainIndex + 3) : url.indexOf("/");

      if (pathStart !== -1) {
        return url.substring(pathStart);
      }

      return "/";
    },

    async signFile(file) {
      try {
        this.setFileInProgress(file);
        const inputLink = this.normalizeUrl(file.url);
        let originalFileLink = null;
        if(file.original) {
          originalFileLink = this.normalizeUrl(file.original);
        }

        const response = await axios.get(process.env.VUE_APP_SERVER_LINK + inputLink, {
          responseType: 'arraybuffer',
          headers: !this.isAdmin ? {} : {
            "x-access-token": this.getToken()
          },
        });
        let arrayBuffer = response.data;
        let uint8Array = new Uint8Array(arrayBuffer);

        // @ts-ignore
        const fileObj = new File([arrayBuffer], file.name);
        arrayBuffer = null;

        let uint8ArrayOriginal = null;

        if(file.original) {
          originalFileLink = this.normalizeUrl(file.original);
          const responseOriginal = await axios.get(process.env.VUE_APP_SERVER_LINK + originalFileLink, {
            responseType: 'arraybuffer',
            headers: !this.isAdmin ? {} : {
              "x-access-token": this.getToken()
            },
          });
          let arrayBuffer = responseOriginal.data;
          uint8ArrayOriginal = new Uint8Array(arrayBuffer);
          arrayBuffer = null;
        }

        // @ts-ignore
        await __SIGN__.signFileRaw(file.name, uint8Array, fileObj, uint8ArrayOriginal, this.endSign, this.nonSignedFilesCount)

        uint8Array.fill(0);

        this.files[file.idx].signed = true;

      } catch (error) {
        console.error('Error signing file:', error);
      } finally {
        this.files[file.idx].inProgress = false;
      }
    },

    async signAllFiles() {
      this.signingInProgress = true;
      this.allFilesCount = this.files.length;
      this.signedAlreadyCounter = 0;
      this.files.forEach((file, idx) => {
        this.files[idx].idx = idx;
      })
      let files = JSON.parse(JSON.stringify(this.files));
      if(!this.isAdmin) {
        files = this.files.filter((file) => !file.signed)
      }
      for (const file of files) {
        await this.signFile(file)
        this.signedAlreadyCounter++;
      }


      this.signingInProgress = false;
    },

    endSign() {
          this.allFileSigned = true;
          if(!this.isAdmin) {
            this.$emit('already-sign');
          }
          this.$emit('hideSign');
          if(this.isAdmin) {
            this.$emit('signedBy', 'admin');
            this.$emit('setFullSigned');
          }
          // @ts-ignore
          __UI__.__LOADER_SCREEN__.hideLoader();
    },

    onFileSignClick() {
      // @ts-ignore
      __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.signFile"), '255,255,255,1', '0,0,0,1');
      this.signAllFiles();
    },
    initialize() {
      // @ts-ignore
      setURL_XML_HTTP_PROXY_SERVICE(process.env.VUE_APP_SERVER_LINK + "/sign-proxy");
      // @ts-ignore
      setRoomId(this.roomId);
      // @ts-ignore
      setSaveUrl(process.env.VUE_APP_SERVER_LINK + "/sign-save");
      // @ts-ignore
      resetSignedSavedFilesCounter();
      //@ts-ignore
      setNotificationFunc(this.errorNotificationFunc);
      //@ts-ignore
      EUSignCPModuleInitializing();
    },
    setFileInProgress(file) {
      file.inProgress = true;
      file.signed = false;
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem("locale", locale);
        this.$router.go(0);
      }
    },

    loadFiles() {
      //@ts-ignore
      __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.init"), '255,255,255,1', '0,0,0,1', 'width: auto;');
      this.fetchFiles().then(() => {
        //@ts-ignore
        __UI__.__LOADER_SCREEN__.hideLoader();
      });
    },

    loadFilesAndInitializeLib() {
      //@ts-ignore
      if(!__UI__.__LOADER_SCREEN__.initialize) {
        //@ts-ignore
        __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.init"), '255,255,255,1', '0,0,0,1', 'width: auto;');
      }
      this.fetchFiles()
          .then(() => {
            if(!this.loaded && !this.allFileSigned && !this.notFoundFilesToSign) {
              this.initialize()
            }
            this.loaded = true;
          })
          .catch((error) => {
            console.log(error, 'ERROR fetchFiles')
          });
    },

    isScriptAdded(src) {
      return Boolean(document.querySelector('script[src="' + src + '"]'));
    },

    scriptsAlreadyLoaded() {
        const mustBeLoaded = this.scriptUrls.length;

        for(let script of this.scriptUrls) {
          const isScriptAdded = this.isScriptAdded(script);
          this.alreadyLoaded = isScriptAdded ? this.alreadyLoaded + 1 : this.alreadyLoaded;
        }

        const fullyLoad = this.alreadyLoaded === mustBeLoaded;

        if(fullyLoad) {
          this.loadFilesAndInitializeLib()
        }

        return fullyLoad;
    },

    loadScriptsOnSignRoute() {

      if(this.scriptsAlreadyLoaded()) {
        this.currentIndex = this.scriptUrls.length + 1;
      }

      if (this.currentIndex < this.scriptUrls.length) {
        const script = document.createElement("script");
        script.src = this.scriptUrls[this.currentIndex];
        script.onload = () => {
          if (this.currentIndex === 0) {
            //@ts-ignore
            __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.init"), '255,255,255,1', '0,0,0,0.7', 'width: auto;');
          }
          this.currentIndex++;

          if (this.currentIndex === 4) {
            this.loadFilesAndInitializeLib()
            if(this.isAdmin) {
              this.$emit('signedBy', 'client');
            }
          }

          return this.loadScriptsOnSignRoute();
        };

        if(!this.isScriptAdded(script)) {
          document.head.appendChild(script);
        }
      }
    },
  },
  mounted() {
    if (this.$route.query.locale) {
      this.switchLocale(this.$route.query.locale);
    }
    this.loadScriptsOnSignRoute()
  },
});
